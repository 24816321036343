import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiCore } from 'src/app/config/api';
import { ApiService } from 'src/app/core/http/api.service';

@Component({
  selector: 'app-configuration-casino-general',
  templateUrl: './configuration-casino-general.component.html',
  styleUrls: ['./configuration-casino-general.component.css'],
  providers: [MessageService]
})

export class ConfigurationCasinoGeneralComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<boolean>();
  public casinoForm!: FormGroup;
  configId: string = '';
  showConfirmSaveDialog: boolean = false;
  showProgressBar: boolean = false;
  showLoadSkeleton: boolean = false;
  countries_code_catalog: any[] = [];
  timezone_catalog: any[] = [];
  language_catalog: any[] = [];
  messaging_services_catalog: any[] = [];
  isButtonDisabled: boolean = true;
  logo: any = null;
  image: any = null;
  specialCharRegex: RegExp = /^[a-zA-Z0-9\s]*$/;
  validEmailRegex: RegExp = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  onlyNumbersRegex: RegExp = /^[0-9]\d*$/;
  defaultValues = {
    casino_name: null,
    web_direction: null,
    logo: null,
    contact_email: null,
    country_code: null,
    contact_phone: null,
    message_services: [],
    timezone: null,
    language: null
  }
  incomplete: boolean = false;
  isSaved: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private api: ApiService,
    private activRoute: ActivatedRoute
    ) {
      const incomplete = this.activRoute.snapshot.queryParamMap.get('incomplete');
      if(incomplete){
        this.incomplete = true;
      }
      this.casinoForm = this.formBuilder.group({
        casino_name: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(30), Validators.pattern(this.specialCharRegex)]],
        web_direction: [null],
        contact_email: [null, [Validators.required, Validators.pattern(this.validEmailRegex)]],
        country_code: [null],
        contact_phone:[null, [Validators.required, Validators.minLength(6), Validators.maxLength(12), Validators.pattern(this.onlyNumbersRegex)]],
        timezone: [null, [Validators.required]],
        language: [null, [Validators.required]],
        messaging_services: [null, [Validators.required]]
      });
  }
  
  ngOnInit(): void {
    this.getCountryCodes();
    this.getTimezoneCatalog();
    this.getLanguageCatalog();
    this.getMessagingServicesCatalog();
    this.getOperatorConfig();
  }

  copyTextToClipBoard = () => {
    console.log(this.casinoForm.get("web_direction")?.value)
    navigator.clipboard.writeText(this.casinoForm.get("web_direction")?.value).catch(() => {
      console.error("Unable to copy text");
      this.messageService.add({key: 'bc', severity:'error', summary: 'Ocurrio un error al tratar de copiar enlace'});
    });
    this.messageService.add({key: 'bc', severity:'success', summary: 'El enlace ha sido copiado al portapapeles', life: 1500, closable: false});
  }

  getOperatorConfig = () => {
    this.showLoadSkeleton = true;
    this.api.list('operator/operator-config').subscribe((res) => {
      //console.log(res);
      this.defaultValues.web_direction = res.data.guestUrl;
      this.defaultValues.casino_name = res.data.casinoName;
      this.defaultValues.logo = res.data.logoCasinoUrl;
      this.defaultValues.contact_email = res.data.contactEmail;
      this.defaultValues.country_code = res.data.countryCodeId;
      this.defaultValues.contact_phone = res.data.contactPhone;
      this.defaultValues.message_services = res.data.mesaggingServices.map((item: any) => item.messagingServiceId);
      this.defaultValues.timezone = res.data.timeZoneCasinoCatId;
      this.defaultValues.language = res.data.languageCasinoCatId;

      //set Values
      this.configId = res.data.id;
      this.casinoForm.get("web_direction")?.setValue(res.data.guestUrl);
      this.logo = res.data.logoCasinoUrl;
      this.casinoForm.get("casino_name")?.setValue(res.data.casinoName);
      this.casinoForm.get("contact_email")?.setValue(res.data.contactEmail);
      this.casinoForm.get("country_code")?.setValue(res.data.countryCodeId);
      this.casinoForm.get("contact_phone")?.setValue(res.data.contactPhone);
      let messagingServices = res.data.mesaggingServices.map((item: any) => item.messagingServiceId);
      this.casinoForm.get("messaging_services")?.setValue(messagingServices);
      this.casinoForm.get("timezone")?.setValue(res.data.timeZoneCasinoCatId);
      this.casinoForm.get("language")?.setValue(res.data.languageCasinoCatId);
      setTimeout(() => {
        this.showLoadSkeleton = false;
      }, 1000);
    });
  }

  getCountryCodes = () => {
    this.showLoadSkeleton = true;
    this.api.list('catalogs/country-codes-catalog').subscribe((res) => {
      this.countries_code_catalog = res.data;
      setTimeout(() => {
        this.showLoadSkeleton = false;
      }, 750);    });
  }

  getTimezoneCatalog = () => {
    this.showLoadSkeleton = true;
    this.api.list('catalogs/timezone-catalog').subscribe((res) => {
      this.timezone_catalog = res.data;
      setTimeout(() => {
        this.showLoadSkeleton = false;
      }, 750);    });
  }

  getLanguageCatalog = () => {
    this.showLoadSkeleton = true;
    this.api.list('catalogs/language-catalog?isActive=true').subscribe((res) => {
      this.language_catalog = res.data;
      setTimeout(() => {
        this.showLoadSkeleton = false;
      }, 750);
    });
  }

  getMessagingServicesCatalog = () => {
    this.showLoadSkeleton = true;
    this.api.list('catalogs/messaging-services-catalog?isActive=true').subscribe((res) => {
      this.messaging_services_catalog = res.data;
      setTimeout(() => {
        this.showLoadSkeleton = false;
      }, 750);
    });
  }

  loadLogo = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpeg, .jpg';
    input.onchange = (e: any) => {
      const file = e.target.files[0];
      this.image = file;
      if(file.type !== 'image/png' && file.type !== 'image/jpeg'){
        this.messageService.add({key: 'bc', severity:'error', summary: 'Formato de archivo no permitido'});
        return;
      }
      if(file.size > 1000000){
          this.defaultValues.logo === null ? 'assets/images/no-photo-available.png' : this.defaultValues.logo;
          this.messageService.add({key: 'bc', severity:'error', summary: 'La imagen excede el límite de peso'});
          return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.logo = reader.result;
        this.messageService.add({severity:'success', summary: 'Imagen cargada, no olvides guardar tus cambios'});
        this.isButtonDisabled = false;
      };
    };
    input.click();
  }

  closeDialog = () => {
    this.showConfirmSaveDialog = false;
    !this.incomplete ?  document.body.classList.add('p-overflow-hidden') : '';
  }

  showDialog = () => {
    this.isButtonDisabled ? this.showConfirmSaveDialog = false : this.showConfirmSaveDialog = true;
  }

  dataSaved = () => {
    this.messageEvent.emit(this.isSaved);
  }

  save = () => {
    this.showConfirmSaveDialog = true;
    const data = {
      //id: this.configId,
      // guestUrl: this.generalForm.get("web_direction")?.value,
      casinoName: this.casinoForm.get('casino_name')?.value,
      contactEmail: this.casinoForm.get("contact_email")?.value,
      countryCodeId: this.casinoForm.get("country_code")?.value,
      contactPhone: this.casinoForm.get("contact_phone")?.value,
      messagingServices: this.casinoForm.get("messaging_services")?.value,
      timeZoneCasinoCatId: this.casinoForm.get("timezone")?.value,
      languageCasinoCatId: this.casinoForm.get("language")?.value,
    }
    
    const imageUpload = {
      file: this.image,
      id: this.configId
    }

    this.showProgressBar = true;
    this.api.updateDirect('operator/operator-config', data).subscribe(response => {
      //console.log(response)
      if(response.statusCode === 200){
        if(this.defaultValues.logo !== this.logo){
          this.saveImage(imageUpload);
        }else{
          this.showProgressBar = false;
          this.showConfirmSaveDialog = false;
          setTimeout(() => {
            this.messageService.add({severity:'success', summary: 'Cambios aplicados satisfactoriamente'});
          }, 300);
          this.getOperatorConfig();
          this.dataSaved();
          this.isButtonDisabled = true;
        }
        this.saveMessagingService();
      }else{
        this.showProgressBar = false;
        setTimeout(() => {
          this.messageService.add({severity:'error', summary: 'Ha ocurrido un error al querer guardar los cambios'});
        }, 300);
      }
    },(err) => {
      console.log(err);
    })
  }

  saveImage = (data:any) => {
    this.showProgressBar = true;
    this.api.uploadOperatorLogo('operator/upload-logo-casino-img', data).subscribe(response => {
      if(response.statusCode === 200){
        this.showProgressBar = false;
        this.showConfirmSaveDialog = false;
        setTimeout(() => {
          this.messageService.add({severity:'success', summary: 'Cambios aplicados satisfactoriamente'});
        }, 300);
        this.getOperatorConfig();
        this.dataSaved();
        this.isButtonDisabled = true;
      }
    })
  }

  saveMessagingService = () => {
    const bulkCreate: { operatorConfigId: string; messagingServiceId: any; }[] = [];
    this.casinoForm.get("messaging_services")?.value.forEach((element: any) => {
      const data = {
        operatorConfigId: this.configId,
        messagingServiceId: element,
      }
      bulkCreate.push(data);
    });
    this.api.create(ApiCore.MESSAGING_SERVICES, bulkCreate).subscribe((res) => {
        if(res.statusCode === 201){
          this.getOperatorConfig();
          this.dataSaved();
        }
      }, (err) => {
        console.log(err);
      });
  }
  
  changeSelect() {
    console.log(this.casinoForm.get('contact_phone')?.errors)
    if(this.defaultValues.casino_name === this.casinoForm.get("casino_name")?.value && 
    this.defaultValues.contact_email === this.casinoForm.get("contact_email")?.value &&
    this.defaultValues.country_code === this.casinoForm.get('country_code')?.value &&
    this.defaultValues.contact_phone === this.casinoForm.get("contact_phone")?.value &&
    this.defaultValues.timezone === this.casinoForm.get("timezone")?.value &&
    this.defaultValues.language === this.casinoForm.get("language")?.value){
      this.isButtonDisabled = true;
    }else{
      if(this.casinoForm.valid){
        this.isButtonDisabled = false;
      }else{
        this.isButtonDisabled = true;
      }
    }
  }

  changeMessageService = (e:any) => {
    const result = this.defaultValues.message_services.filter((element, index) => e[index] === element);
    if(result.length !== this.defaultValues.message_services.length){
      if(this.casinoForm.valid){
        this.isButtonDisabled = false;
      }else{
        this.isButtonDisabled = true;
      }
    }else{
      let count = 0;
      this.defaultValues.message_services.filter((element) => {
        this.casinoForm.get('messaging_services')?.value.forEach((e:any) => {
          if(e === element){
            count++;
          }
        });
      })
      if(count !== this.casinoForm.get('messaging_services')?.value.length){
        this.isButtonDisabled = false;
      }else{
        this.isButtonDisabled = true;
      }
    }
  }

  validateNumber = (event: KeyboardEvent) =>{
    const keyCode = event.keyCode;
    if (keyCode < 48 || keyCode > 57) {
      if(keyCode !== 8 && keyCode !== 9){
        event.preventDefault();
      }
    }
  }
}
