import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type Severity = 'success' | 'info' | 'warn' | 'error';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  onNotifyEvent$ = new Subject<any>();
  constructor() {}

  toast(severity: Severity = 'success', summary: string = '', life?:number) {
    this.onNotifyEvent$.next({
      key: 'top-center',
      severity: severity,
      summary: summary,
      life: life
    });
  }
}