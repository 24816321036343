<form [formGroup]="generalForm">
<!-- seccion idioma y horario -->
  <div  class="flex flex-column pt-4 pb-4">
    <span class="Title mb-4">General</span>
    <span class="Title">Time Zone</span>
    <span class="ContentText">By changing the Time Zone, all past and future records' dates and times will be automatically adjusted to the newly selected Time Zone.</span>
    <div class="mt-2 flex flex-column w-10" >
      <span class="LabelText mb-1">Time Zone</span>
      <!-- <input formControlName="web_direction" autocomplete="off" pInputText placeholder="Dirección Web" style="height:25px" readonly> -->
      <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem"></p-skeleton>
      <p-dropdown *ngIf="!showLoadSkeleton" formControlName="timezone" (onChange)="changeSelect()" [options]="timezone_catalog" optionLabel="name" optionValue="id" [filter]="false" [showClear]="false" placeholder="Seleccione una zona horaria" ></p-dropdown>
    </div>
    <div class="mt-2 flex flex-column w-10">
      <span class="LabelText mb-1">Current Date</span>
      <!-- <input formControlName="web_direction" autocomplete="off" pInputText placeholder="Dirección Web" style="height:25px" readonly> -->
      <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem" ></p-skeleton>
      <span class="p-input-icon-right">
        <i *ngIf="!showLoadSkeleton" class="pi pi-calendar"></i>
        <input *ngIf="!showLoadSkeleton" class="disabledInput" formControlName="current_date" [value]="generalForm.get('current_date')?.value" autocomplete="off" pInputText placeholder="Current Date" style="height:2.5rem" readonly >
      </span>

    </div>
    <p-button icon="pi pi-save" label="Save" [disabled]="isButtonDisabled" class="p-button-raised mt-4 w-13rem" (click)="showDialog()"></p-button>
    
  </div>
  <!-- seccion logotipo -->


</form>
<p-toast position="top-center"></p-toast>

<!--dialog section-->
<p-dialog appendTo ="body"  [style]="{width: '500px'}" [modal]="true" [draggable]="false" [(visible)]="showConfirmSaveDialog" (onHide)="closeDialog()" [dismissableMask]=true>
  <p-header >
    <div style="justify-content: left; flex-direction: row; display: flex; align-items: left;">
      <i class="pi pi-exclamation-triangle" style="font-size: 2rem; padding-top: 5px;"></i>
      <label class="header_title" >Confirm Changes</label>
    </div>
  </p-header>
  <div style="justify-content: left; display: flex; flex-wrap: wrap;">
    <span>By confirming this window, the changes will be applied in the platform.</span>
    
  </div>
  <div style="justify-content: left; display: flex; flex-wrap: wrap;padding-top: 45px;">
    <span>Do you wish to continue?</span>
    
  </div>
  <p-footer>
    <div *ngIf="!showProgressBar" style="display: flex; justify-content:right;" >
      <!-- <button type="button" style="width: 40%;" pButton label="" (click)="closeDialog()"></button> -->
      <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text" label="Cancel" (click)="closeDialog()"></button>
      <button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-text" label="Save and update" (click)="save()"></button>
    </div>
    <p-progressBar *ngIf="showProgressBar" mode="indeterminate" [style]="{'height': '4px'}"></p-progressBar>
  </p-footer>
</p-dialog>

<!-- hasta esperar reubicacion -->

<!--<div style="padding: 0px 0px 10px 10px;">
   seccion general
  <div style="display: flex; flex-direction: column; padding: 20px 20px; border-bottom: 2px; border-bottom-style: solid;">
    <span class="Title">General</span>
    <span class="SubTitle" style="margin-top: 15px;">Plataforma para Jugadores</span>
    <span class="ContentText">Esta es la dirección web que utilizarán tus jugadores para acceder a tu plataforma para juagadores.</span>
    <div style="margin-top: 10px; width: 300px; display: flex; flex-direction: column;" >
      <span class="LabelText" style="margin-bottom: 5px;">Dirección Web</span>
      <span class="p-input-icon-right">
        <i class="pi pi-copy" style="cursor:pointer;" (click)="CopyTextToClipBoard()"></i>
        <input maxlength="16" formControlName="web_direction" autocomplete="off" pInputText placeholder="Dirección Web" style="height:25px" readonly>
      </span>
    </div>
  </div>
      seccion contacto
  <div style="display: flex; flex-direction: column; padding: 20px 20px; border-bottom: 2px; border-bottom-style: solid;">
    <span class="Title">Contacto</span>
    <span class="ContentText">La información registrada en los siguientes campos será ofrecida a tus jugadores como medios de contacto para soporte y atención a clientes.</span>
    <div style="margin-top: 10px; width: 360px; display: flex; flex-direction: column;" >
      <span class="LabelText" style="margin-bottom: 5px;">Correo de contacto</span>
      <input formControlName="contact_email" autocomplete="off" pInputText placeholder="Correo de contacto" style="height:25px">
    </div>
    <div style="display: flex; flex-direction: row;">
      <div style="margin-top: 10px; margin-right: 10px; width: 150px; display: flex; flex-direction: column;" >
        <span class="LabelText" style="margin-bottom: 5px;">Código de pais</span>
        <p-dropdown formControlName="country_code" (onChange)="changeSelect()" [options]="countries_code_catalog" optionLabel="ladaCode" optionValue="id" [filter]="false" filterBy="country"
                    [showClear]="false" placeholder="Seleccione un país">
          <ng-template pTemplate="selectedItem" let-option>
            <div class="country-item country-item-value" style="display: flex;">
              <img src="assets/images/flag/flag_placeholder.png" [class]="'flag flag-' + option.flagCode.toLowerCase()" />
              <div style="margin-left: 10px;">{{option.ladaCode}}</div>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="country-item" style="display: flex;">
              <img src="assets/images/flag/flag_placeholder.png" [class]="'flag flag-' + country.flagCode.toLowerCase()" />
              <div style="margin-left: 10px;">{{country.ladaCode}}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div style="margin-top: 10px; width: 200px; display: flex; flex-direction: column;" >
        <span class="LabelText" style="margin-bottom: 5px;">Teléfono de contacto</span>
        <input formControlName="contact_phone" autocomplete="off" pInputText placeholder="Teléfono de contacto">
      </div>
    </div>
    <div style="margin-top: 10px; width: 360px; display: flex; flex-direction: column;" >
      <span class="LabelText" style="margin-bottom: 5px;">Servicios de Mensajería:</span>
      --comment <input formControlName="web_direction" autocomplete="off" pInputText placeholder="Dirección Web" style="height:25px" readonly>
      <p-multiSelect [options]="messaging_services_catalog" formControlName="messaging_services" display="chip" optionLabel="name" optionValue="id"></p-multiSelect>
    </div>
  </div>

</div>-->
