import { NgModule } from '@angular/core';

import { currencyBRL } from '../pipes/currencybrl.pipe';


@NgModule({
  imports: [],
  exports: [currencyBRL],
  declarations: [currencyBRL],
  providers: [],
})
export class CurrencyBRLModule { }
