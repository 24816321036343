
<!--<div style="padding: 0px 0px;">
  <div style="display: flex; width: 100%; height: 10vh; justify-content: space-between; align-items: center; border-bottom: 2px; border-bottom-style: solid; ">
    <div style="font-size: 30px; font-weight: bold; margin-left: 15px;">
      <i class="pi pi-cog" style="font-size: 2rem"></i>
      Configuración
    </div>
    <div style="font-size: 20px; margin-right: 15px; display: flex; flex-direction: row; cursor: pointer;" routerLink="../">
      <span>Cerrar</span>
      <div style="margin-top: 3px; margin-left: 15px;">
        <i class="pi pi-times" style="font-size: 20px"></i>
      </div>
    </div>
  </div>

</div>-->
<div >
  <p-tag *ngIf="incomplete && isDisabled" value="Configuración incompleta" severity="warning" [rounded]="false" icon="pi pi-exclamation-triangle" styleClass="border-noround w-full" pTooltip="Llene todos los campos para continuar" [fitContent]="true" tooltipPosition="bottom"></p-tag>
  <p-tag *ngIf="incomplete && !isDisabled" value="Configuración completa" severity="success" [rounded]="false" icon="pi pi-check-circle" styleClass="border-noround w-full" pTooltip="Ya puede cerrar esta pantalla" [fitContent]="true" tooltipPosition="bottom"></p-tag>
  <div *ngIf="incomplete" class="flex w-full justify-content-end align-items-center">
    <button pButton pRipple type="button" label="Cerrar" class="p-button-text col-1 text-xl mr-1 mt-2 p-button-secondary" [disabled]="isDisabled" icon="pi pi-times" (click)="goToHome()"></button>
  </div>
  <div class="menu-body w-100 p-4">
    <div class="grid">
      <div class=" col-12 sm:col-12 md:col-3 lg:col-3 xl:col-3 min-w-max" >
        <div style="padding: 15px; display: flex; flex-direction: column;" >
           <!--routerLink="../configuration/general" routerLink="../configuration/credits"  routerLink="../configuration/verification"-->
         
          <button type="button" (click)="selectGeneral()" [ngClass]="isGeneralSelected === true ? 'selected-button' : 'unselected-button'" label="General" pButton ></button>
        </div>
      </div>
      <p-divider class="p-0 m-0 col-12 sm:col-12 md:col-1 lg:col-1 xl:col-1" [layout]="vertical ? 'vertical' : 'horizontal'"></p-divider>
      <div class="col">
        <app-configuration-general *ngIf="isGeneralSelected" (messageEvent)="isDataSavedReceived($event)" class="fadein animation-duration-400"></app-configuration-general>
      </div>
    </div>
  </div>
</div>


