import { NgModule } from '@angular/core';

import { UtcFormatPipe } from '../pipes/utc-format.pipe';

@NgModule({
  imports: [],
  exports: [UtcFormatPipe],
  declarations: [UtcFormatPipe],
  providers: [],
})
export class UtcFormatModule { }
