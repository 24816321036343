import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcFormat',
})
export class UtcFormatPipe implements PipeTransform {
  transform(value: string, offset: number, includeSeconds: boolean = false):string {
    const formattedDate = this.calculateFormattedDate(value, offset, includeSeconds);
    return formattedDate;
  }

  private calculateFormattedDate(value: string, offset: number, includeSeconds: boolean): string {
    const CURRENT_DATE = new Date(value);
    const UTC_TIME = CURRENT_DATE.getTime() + CURRENT_DATE.getTimezoneOffset(); 
    let currentOffset = offset;
    if(currentOffset % 1!==0){ //Significa que tiene decimales y tendremos que ajustarlo
      let arraySplitted = currentOffset.toString().split('.')
      let decimalOffset = arraySplitted.pop(); //Obtenemos el ultimo valor despues del punto
      if(decimalOffset?.length===1) decimalOffset = decimalOffset+'0'; //hace que el numero sea una decena
      const numberOffset = Number(decimalOffset)/60 // se divide por 60 minutos para hacer la medida
      currentOffset = Number(arraySplitted[0])+numberOffset; //ahora solo se le suma lo del primer valor sin el decimal
    }
    const ADJUSTED_TIME = UTC_TIME + currentOffset * 60 * 60 * 1000; 
    const ADJUSTED_DATE = new Date(ADJUSTED_TIME);
    const year = ADJUSTED_DATE.getUTCFullYear();
    const month = (ADJUSTED_DATE.getUTCMonth() + 1).toString().padStart(2, '0'); 
    const day = ADJUSTED_DATE.getUTCDate().toString().padStart(2, '0');
    const hours = ADJUSTED_DATE.getUTCHours().toString().padStart(2, '0');
    const minutes = ADJUSTED_DATE.getUTCMinutes().toString().padStart(2, '0');
    const seconds = ADJUSTED_DATE.getUTCSeconds().toString().padStart(2, '0');
    let formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
    if (includeSeconds) {
      formattedDate += `:${seconds}`;
    }
    return formattedDate;
  }
}
