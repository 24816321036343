import { Component,/* OnDestroy, OnInit*/OnInit  } from '@angular/core';
import { filter, map, mergeMap } from "rxjs/operators";
import { AppBreadcrumbService } from '../../Services/app.breadcrumb.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
})
export class AppBreadcrumbComponent implements OnInit {

  public breadcrumbs: MenuItem[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public breadcrumbService: AppBreadcrumbService,
    //public appMenu: AppMenuComponent
  ) {
    this.dynamicBreadcrumb();
  }

  ngOnInit() {
    this.breadcrumbService.itemsHandler.subscribe((breadcrumb) => {
      this.breadcrumbs = breadcrumb;
    });
  }
  dynamicBreadcrumb = () => {
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === "primary"),
      mergeMap(route => route.data)
    )
    .subscribe(event => {
      this.breadcrumbs = event['breadcrumb']
    }  
    );
  }
}
