import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiService } from '../../../../core/http/api.service';
import { EventService } from '../services/utc.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-configuration-general',
  templateUrl: './configuration-general.component.html',
  styleUrls: ['./configuration-general.component.css'],
  providers: [MessageService],
})
export class ConfigurationGeneralComponent implements OnInit {
  public offsetUTC: number = 0;

  @Output() messageEvent = new EventEmitter<boolean>();
  showConfirmSaveDialog: boolean = false;
  configId: string = '';
  logo: any = null;
  image: any;
  isButtonDisabled: boolean = true;
  showProgressBar: boolean = false;
  countries_code_catalog: any[] = [];
  messaging_services_catalog: any[] = [];
  timezone_catalog: any[] = [];
  language_catalog: any[] = [];
  timeZoneUtcSelected: string = '';
  defaultValues = {
    timezone: null,
    language: null,
    logo: null,
    current_date: new Date(),
  };

  showLoadSkeleton: boolean = false;
  incomplete: boolean = false;
  isSaved: boolean = true;

  public generalForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private messageService: MessageService,
    private activRoute: ActivatedRoute,
    private eventService: EventService
  ) {
    this.eventService.reloadSellComponent.subscribe(() => {
      // this.getOperatorConfig();
      //TODO: Change this to
      this.loadOffset();
    });
    const incomplete = this.activRoute.snapshot.queryParamMap.get('incomplete');
    if (incomplete) {
      this.incomplete = true;
    }
    this.generalForm = this.formBuilder.group({
      timezone: [null, [Validators.required]],
      language: [null, [Validators.required]],
      current_date: [null, [Validators.required]],
    });
  }

  async ngOnInit() {
    this.offsetUTC = await this.loadOffset();
    await this.getTimezoneCatalog();
    await this.getLanguageCatalog();
    await this.getAggregatorWebConfig();
  }
  async getAggregatorWebConfig() {
    this.showLoadSkeleton = true;
    await this.api.list('aggregator/aggregator-web-config').subscribe((res) => {
      this.defaultValues.timezone = res.data.timeZoneCatId;

      this.timeZoneUtcSelected = res.data.timeZoneCat.description;

      this.defaultValues.logo = res.data.logoUrl;

      const formattedDate = this.getFormattedDateWithUtcOffset(this.offsetUTC);
      this.generalForm.get('current_date')?.setValue(formattedDate);
      
      this.generalForm.get('timezone')?.setValue(res.data.timeZoneCatId);

      setTimeout(() => {
        this.showLoadSkeleton = false;
      }, 750);
    });
  }

  getFormattedDateWithUtcOffset(utcOffsetHours: number): string {
    const CURRENT_DATE = new Date();
    const UTC_TIME = CURRENT_DATE.getTime() + CURRENT_DATE.getTimezoneOffset();
    let currentOffset = utcOffsetHours;
    if (currentOffset % 1 !== 0) {
      //Significa que tiene decimales y tendremos que ajustarlo
      let arraySplitted = currentOffset.toString().split('.');
      let decimalOffset = arraySplitted.pop(); //Obtenemos el ultimo valor despues del punto
      if (decimalOffset?.length === 1) decimalOffset = decimalOffset + '0'; //hace que el numero sea una decena
      const numberOffset = Number(decimalOffset) / 60; // se divide por 60 minutos para hacer la medida
      currentOffset = Number(arraySplitted[0]) + numberOffset; //ahora solo se le suma lo del primer valor sin el decimal
    }
    const ADJUSTED_TIME = UTC_TIME + currentOffset * 60 * 60 * 1000;
    const ADJUSTED_DATE = new Date(ADJUSTED_TIME);
    const year = ADJUSTED_DATE.getUTCFullYear();
    const month = (ADJUSTED_DATE.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = ADJUSTED_DATE.getUTCDate().toString().padStart(2, '0');
    const hours = ADJUSTED_DATE.getUTCHours().toString().padStart(2, '0');
    const minutes = ADJUSTED_DATE.getUTCMinutes().toString().padStart(2, '0');
    const seconds = ADJUSTED_DATE.getUTCSeconds().toString().padStart(2, '0');
    let formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    return formattedDate;
  }

  getTimezoneCatalog() {
    this.showLoadSkeleton = true;
    this.api.list('catalogs/timezone-catalog').subscribe((res) => {
      this.timezone_catalog = res.data;
      setTimeout(() => {
        this.showLoadSkeleton = false;
      }, 750);
    });
  }

  getLanguageCatalog() {
    this.showLoadSkeleton = true;
    this.api
      .list('catalogs/language-catalog?isActive=true')
      .subscribe((res) => {
        this.language_catalog = res.data;
        setTimeout(() => {
          this.showLoadSkeleton = false;
        }, 750);
      });
  }

  changeSelect() {
    const desiredId = this.generalForm.get('timezone')?.value;
    const selectedTimeZone = this.timezone_catalog.find(
      (timeZone) => timeZone.id === desiredId
    );

    const utcOffsetString = selectedTimeZone.name;
    // const match = utcOffsetString.match(/UTC([+-]\d+):(\d+)/);
    const newMatch = utcOffsetString.match(/UTC[\W]\d{2}:\d{2}/gi);
    if (!newMatch) throw new Error('Invalid utcOffset');
    const result = newMatch[0].slice(3, newMatch[0].length); //extraemos 3 del string match para quitar "UTC"
    const number = Number(result.split(':').join('.')); // Quitamos los dos puntos y unimos el resultado
    //Evaluamos si no es numero es porque se encontró con el 000
    if (isNaN(number)) {
      this.offsetUTC = 0;
      const formattedDate = this.getFormattedDateWithUtcOffset(this.offsetUTC);
      this.generalForm.get('current_date')?.setValue(formattedDate);
    }else{
      this.offsetUTC = number;
      const formattedDate = this.getFormattedDateWithUtcOffset(this.offsetUTC);
      this.generalForm.get('current_date')?.setValue(formattedDate);
    }

    if (
      this.defaultValues.timezone === this.generalForm.get('timezone')?.value
    ) {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  saveImage = (data: any) => {
    this.showProgressBar = true;
    this.api
      .uploadOperatorLogo('operator/upload-logo-img', data)
      .subscribe((response) => {
        if (response.statusCode === 200) {
          this.showProgressBar = false;
          this.showConfirmSaveDialog = false;
          setTimeout(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Cambios aplicados satisfactoriamente',
            });
          }, 300);

          this.dataSaved();
          this.isButtonDisabled = true;
        }
      });
  };

  dataSaved = () => {
    this.messageEvent.emit(this.isSaved);
  };

  save() {
    this.showConfirmSaveDialog = true;

    const data = {
      timeZoneCatId: this.generalForm.get('timezone')?.value,
    };

    this.showProgressBar = true;
    this.api.updateDirect('aggregator/aggregator-config', data).subscribe(
      (res) => {
        if (res.statusCode === 200) {
          this.showProgressBar = false;
          this.showConfirmSaveDialog = false;
          setTimeout(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Platform succesfully updated',
            });
          }, 300);

          this.dataSaved();
          this.isButtonDisabled = true;
          this.getAggregatorWebConfig();
          this.eventService.reloadSellComponent.emit();
        } else {
          this.showProgressBar = false;
          setTimeout(() => {
            this.messageService.add({ severity: 'error', summary: 'Error' });
          }, 300);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  closeDialog = () => {
    this.showConfirmSaveDialog = false;
    !this.incomplete ? document.body.classList.add('p-overflow-hidden') : '';
  };

  showDialog = () => {
    this.isButtonDisabled
      ? (this.showConfirmSaveDialog = false)
      : (this.showConfirmSaveDialog = true);
  };

  async loadOffset(): Promise<number> {
    this.api.userOffsetHours.subscribe((res) => {
      this.offsetUTC = res;
    });
    return firstValueFrom(this.api.userOffsetHours)
  }
}
