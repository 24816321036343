import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { SessionService } from 'src/app/core/auth/session.service';
import { ApiService } from 'src/app/core/http/api.service';
import { MainLayoutComponent } from '../../main-layout/main-layout.component';
import { AppBreadcrumbService } from '../../Services/app.breadcrumb.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  public model: MenuItem[] = [];
  appLogo: string | null = 'assets/images/no-photo-available.png';
  showLoadSpin: boolean = false;

  showMenu: boolean = false;

  constructor(
    public app: AppComponent,
    public appMain: MainLayoutComponent,
    private session: SessionService,
    private apiService: ApiService,
    private router: Router,
    private breadcrumb: AppBreadcrumbService
  ) {
    
  }
  
  private clickedInside: boolean = false;

  @HostListener('click')
  clickInside() {
    this.clickedInside = true;
  }

  @HostListener('document:click')
  clickOutside() {    
    if(!this.clickedInside) {
      this.showMenu = false;
    }
    this.clickedInside = false;
  }

  ngOnInit() {
    this.model = [
      {
        label: 'Clients',
        icon: 'pi pi-briefcase',
        routerLink: '/app/customers',
      },
      {
        label: 'Providers',
        icon: 'pi pi-database',
        routerLink: '/app/suppliers',
      },
      {
        label: 'Projects',
        icon: 'pi pi-sitemap',
        routerLink: '/app/aggregator',
      },
      {
        label: 'Finance',
        icon: 'pi pi-money-bill',
        expanded: this.validateMenuExpand(['/app/finance/clients', '/app/finance/providers']),
        items: [
          {
            label: 'Clients',
            icon: 'pi pi-box',
            routerLink: '/app/finance/clients',
          },
          {
            label: 'Providers',
            icon: 'pi pi-box',
            routerLink: '/app/finance/providers',
          },
        ],
      },
    ];

    //this.getCoorporateConfiguration();
    this.appLogo = 'assets/images/aggregator.png';
  }

  setMenuData(data: any){
    return data;
  }
  // param: array of routerLink of all items
  validateMenuExpand = (routes:Array<string>):boolean => {
    const res = routes.map(i => this.router.url.includes(i));
    const active = res.find(i => i === true);
    return active === true ?  true : false;
  }

  public async getCoorporateConfiguration() {
    try {
      /* this.showLoadSpin = true;
      await this.apiService.GetOperatorConfig().subscribe((res) => {
        this.session.setItem('operatorLogo', res.data.logoUrl);
        this.appLogo = res.data.logoUrl;
        this.showLoadSpin = false;
      }); */
    } catch (error) {
      alert('Error happened');
      console.error(error);
    }
  }
}
