import { formatDate } from "@angular/common";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class Utilities {

    // checks if an object contains null or empty fields, and if the object contains an array checks if it's empty and return true or false
    public static hasNull (data: any): boolean {
        let output = false;
        for (const key in data) {
            if(data[key] === null || data[key] === undefined || data[key].length === 0){
                output = true;
            }
        }
        return output;
    }

    public static getFirstNLastMonthDay = (monthNumber: number, year: number) => {
      const date = new Date();
      const currentYear = year === 0 ? date.getFullYear() : year;
      date.setFullYear(currentYear, monthNumber - 1, 1);
      const firstDay = new Date(year === 0 ? date.getFullYear() : year, date.getMonth(), 1);
      const lastDay = new Date(year === 0 ? date.getFullYear() : year, date.getMonth() + 1, 0);
      return { firstDay, lastDay };
    } 
    public static Calendar_ES = {
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        today: 'Hoy',
        clear: 'Limpiar',
    }

    public static formatDateDataBase(dateToDB: Date) {
        return formatDate(dateToDB, "yyyy-MM-dd", "en-US");
    }

    public static formatDateDMY(dateDMY: Date) {
        return formatDate(dateDMY, "dd/MM/yyyy", "es-MX");
    }

    public static numbToletters(count: number) {
        const letters = [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "AA",
          "AB",
          "AC",
          "AD",
          "AE",
          "AF",
          "AG",
          "AH",
          "AI",
          "AJ",
          "AK",
          "AL",
          "AM",
          "AN",
          "AO",
          "AP",
          "AQ",
          "AR",
          "AS",
          "AT",
          "AU",
          "AV",
          "AW",
          "AX",
          "AY",
          "AZ",
          "BA",
          "BB",
          "BC",
          "BD",
          "BE",
          "BF",
          "BG",
          "BH",
          "BI",
          "BJ",
          "BK",
          "BL",
          "BM",
          "BN",
          "BO",
          "BP",
          "BQ",
          "BR",
          "BS",
          "BT",
          "BU",
          "BV",
          "BW",
          "BX",
          "BY",
          "BZ",
          "CA",
          "CB",
          "CC",
          "CD",
          "CE",
          "CF",
          "CG",
          "CH",
          "CI",
          "CJ",
          "CK",
          "CL",
          "CM",
          "CN",
          "CO",
          "CP",
          "CQ",
          "CR",
          "CS",
          "CT",
          "CU",
          "CV",
          "CW",
          "CX",
          "CY",
          "CZ",
          "DA",
          "DB",
          "DC",
          "DE",
          "DF",
          "DG"
        ];
        const outputArray = [];
        for (let index = 0; index < count; index++) {
          outputArray[index] = letters[index];
        }
        return outputArray;
      }

      public static onlyNumbersValidator(): ValidatorFn {
        return (control: AbstractControl) => {
          const numericValue = control.value?.replace(/[^0-9]/g, '');
          if (control.value !== numericValue) {
            control.setValue(numericValue);
          }
          return null;
        };
      }
    
      public static onlyTextValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
          if (control.value) {
            const textValue = control.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s']/g, '');
            if (control.value !== textValue) {
              control.setValue(textValue);
            }
          }
          return null;
        };
      }

      public static webAddressValidator(control: AbstractControl): ValidationErrors | null {
        const value: string = control.value || '';
        let isValid = true;

        if(value.startsWith('http://') || value.startsWith('https://')) {
          isValid = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9\.-]{2,}(\.[a-zA-Z0-9]{2,})(\/[-\/a-zA-Z0-9]*)?$/.test(value);
        } else {
          isValid = /^(https?:\/\/)?(www\.)[a-zA-Z0-9\.-]{2,}(\.[a-zA-Z0-9]{2,})(\/[-\/a-zA-Z0-9]*)?$/.test(value);
        }

        if(value.replace('://', '').includes('//')) {
          isValid = false;
        }
        
        return isValid || value === '' ? null : { pattern: true };
      }
}


