import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyBRL'
  })
  export class currencyBRL implements PipeTransform {
    transform(value: number | string): string {
      // transforing data to numericValue
      const numValue = typeof value === 'string' ? parseFloat(value) : value;
      
      // return '' if not num
      if (isNaN(numValue)) {
        return '';
      }

      if(numValue === 0){
        return '0'
      }
      
      // format to a num with extra-two-digits
      const formattedValue = numValue.toFixed(2);
  
      // Regular expression
      const string = formattedValue.split('.');
      string[0] = string[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
      // 
      return string.join(',');
    }
  }