import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ApiService } from 'src/app/core/http/api.service';
import { Utilities } from 'src/app/core/tools/utilities';
import { ApiCore } from '../../../../config/api';
import { HeaderCreditsService } from '../../Services/header-credits.service';
import { Subscription } from 'rxjs';
import { AppBreadcrumbService } from '../../Services/app.breadcrumb.service';

@Component({
  selector: 'app-header-credits',
  templateUrl: './header-credits.component.html',
  styleUrls: ['./header-credits.component.css']
})
export class HeaderCreditsComponent implements OnInit {
  isClose: boolean = true;
  @Output() messageEvent = new EventEmitter<boolean>();
  alertIncompleteData = false;
  isIncomplete:boolean = false;

  items: MenuItem[] = [];
  profilePicture: any = "assets/images/imageProfile.png";
  displaySidebar = false;
  iconSidebar = 'pi pi-cog';
  titleSidebar = 'Settings';
  renderComponent = false;
  creditReserve = 0

  creditReserveSub: Subscription | undefined;
  profilePictureSub: Subscription | undefined;

  hideCreditReserve: boolean = false;

  constructor(
    private i18nService: I18nService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private apiService: ApiService,
    private headerCreditsService: HeaderCreditsService,
    private breadcrumb: AppBreadcrumbService
  ) {
    //this.getOperatorConfig();
    

    this.creditReserveSub = this.headerCreditsService.creditReserve.subscribe((creditReserve) => {
      this.creditReserve = creditReserve;
    })
   
    this.profilePictureSub = this.headerCreditsService.profilePicture.subscribe((profilePicture) => {
      this.profilePicture = profilePicture;
    })
  }

  LoadUserData() {
    this.apiService.list(ApiCore.OPERATOR + '/me').subscribe(
      (res) => {
        if(res.data.profilePictureUrl) {
          this.profilePicture = res.data.profilePictureUrl;
        }
      },
      (error) => {}
    );
  }

  

  isClosedConfig = () => {
    this.messageEvent.emit(this.isClose);
  }

  async ngOnInit(): Promise<void> {
    this.i18nService.localeEvent.subscribe({
      next: () => {
        this.useTranslate();
      },
    });

    this.items = [
      {label: "Settings",icon: 'pi pi-fw pi-cog',command: () => this.openConfig()},
      {label: "Logout", icon: 'pi pi-fw pi-sign-out', command: () => this.logout()}
      
  ];
  //this.getCreditReserveValue(); 

    //this.LoadUserData();

    let routeData = this.route.snapshot.children[0].data;
    this.hideCreditReserve = routeData['hideCreditReserve'] ?? false;
  }

  useTranslate() {
    this.translate.use(this.i18nService.getLanguage());
  }

  openProfile()
  {

  }
  openConfig = () => {
    this.displaySidebar = true;
    this.renderComponent = true;
    //this.router.navigate(['/app/configuration']);
  }

  closeConfig = () => {
    this.displaySidebar = false;
  }

  onCloseConfig = () => {
    this.renderComponent = false;
    this.isClosedConfig()

  }

  logout()
  {
      this.auth.logout();
  }

  getOperatorConfig = () => {
    this.apiService.GetOperatorConfig().subscribe((response) => {
      const {timeZoneCatId, languageCatId, logoUrl, casinoName, logoCasinoUrl, contactEmail, countryCodeId, contactPhone, mesaggingServices, timeZoneCasinoCatId, languageCasinoCatId } = response.data;
      let requeredConfigData = {
        operator: {
          timeZoneCatId,
          languageCatId,
          logoUrl
        },
        casino: {
          casinoName,
          logoCasinoUrl,
          contactEmail,
          countryCodeId,
          contactPhone,
          mesaggingServices,
          timeZoneCasinoCatId,
          languageCasinoCatId,
        }
      }
      //requeredConfigData.casino.casinoName = null;
      let operator = Utilities.hasNull(requeredConfigData.operator);
      let casino = Utilities.hasNull(requeredConfigData.casino);
      if(operator || casino){
        this.alertIncompleteData = true;
        this.isIncomplete = true;      
      }
    });
  }

  goToConfig = (data:boolean) => {
    this.router.navigate(['/app/configuration'], {queryParams: {incomplete: data}});
  }

  getCreditReserveValue = () => {

    console.log("Traer valor de Reserva de créditos")


    this.apiService.list(ApiCore.DASHLET+'creditReserve')
    .subscribe((res)=>{
      console.log("Traer valor de Reserva de créditos")
      console.log(res);
      this.creditReserve = res.data[0].amount;
    })

  }
}
